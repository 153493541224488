import React from 'react';
import { graphql } from 'gatsby';

import { Page } from 'components/_shared/Page';
import { Markdown } from 'components/_shared/Markdown';

import { drids } from '../_constants/drids';

export default function PageJoinUs(props) {
  const { strapiWebsitetermsofservice: page = {} } = props.data;

  return (
    <Page
      drid={drids.PAGE.TERMS_OF_SERVICE}
      className='terms-of-service'
      withContainer
      containerWidth='m'
      meta={{ title: page.seotitle, description: page.seodescription }}
      withConnectSection={false}
    >
      <article>
        <h1 className='font-title text-3xl font-bold text-center lg:text-4xl lg:leading-8'>
          {page.section1title}
        </h1>
        <Markdown className='mt-12' markdown={page.section1body} />
      </article>
    </Page>
  );
}

export const query = graphql`
  query PageTermsOfServiceQuery {
    strapiWebsitetermsofservice {
      ContactBody
      ContactTitle
      section1body
      section1title
      seodescription
      seotitle
    }
  }
`;
